import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoStream from "../components/img/LogoStream"
import PubSearch from "../components/PubMap/Search"

const OurVenuesPage = () => {
  const brands = [
    {
      name: "Harvester",
      logo: "/brand-logos/harvester.png",
      link: "https://www.harvester.co.uk/",
      target: "_blank",
    },
    {
      name: "Toby Carvery",
      logo: "/brand-logos/toby-carvery.png",
      link: "https://www.tobycarvery.co.uk/",
      target: "_blank",
    },
    {
      name: "Miller & Carter",
      logo: "/brand-logos/miller-and-carter.png",
      link: "https://www.millerandcarter.co.uk/",
      target: "_blank",
    },
    {
      name: "Browns",
      logo: "/brand-logos/browns.png",
      link: "https://www.browns-restaurants.co.uk/",
      target: "_blank",
    },
    {
      name: "All Bar One",
      logo: "/brand-logos/all-bar-one.png",
      link: "https://www.allbarone.co.uk/",
      target: "_blank",
    },
    {
      name: "O'Neill's",
      logo: "/brand-logos/oneills.png",
      link: "https://www.oneills.co.uk/",
      target: "_blank",
    },
    {
      name: "Ember Inns",
      logo: "/brand-logos/ember.png",
      link: "https://www.emberinns.co.uk/",
      target: "_blank",
    },
    {
      name: "Nicholson's",
      logo: "/brand-logos/nicholsons.png",
      link: "https://www.nicholsonspubs.co.uk/",
      target: "_blank",
    },
    {
      name: "Sizzling Pubs",
      logo: "/brand-logos/sizzling-pubs.png",
      link: "https://www.sizzlingpubs.co.uk/",
      target: "_blank",
    },
    {
      name: "Stonehouse Pizza & Carvery",
      logo: "/brand-logos/stonehouse.png",
      link: "https://www.stonehouserestaurants.co.uk/",
      target: "_blank",
    },
    {
      name: "Vintage Inns",
      logo: "/brand-logos/vintage-inns.png",
      link: "https://www.vintageinn.co.uk/",
      target: "_blank",
    },
    {
      name: "Ego Mediterranean",
      logo: "/brand-logos/ego.png",
      link: "https://www.egorestaurants.co.uk/",
      target: "_blank",
    },
    {
      name: "Innkeeper's Lodge",
      logo: "/brand-logos/innkeepers-lodge.png",
      link: "https://www.innkeeperslodge.com/",
      target: "_blank",
    },
    {
      name: "Plus 100's more...",
      logo: "/general/100s-more.png",
      link: "/book-a-table/",
      target: null,
    },
  ]

  return (
    <Layout>
      <Seo
        title={`Our Venues`}
        description={`The Dining Out Gift Card can be spent at some of the country's best-known and loved restaurants, bars, pubs and hotels.`}
      />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main">
          <h1>Our Venues</h1>

          <p>
            The Dining Out Gift Card can be spent at some of the country's
            best-known and loved restaurants, bars, pubs and hotels to offer you
            plenty of choice when it comes to drinking, dining... or even
            sleeping out! So whether you're in the mood for cocktails and
            nibbles in a buzzing city centre, a three-course meal somewhere
            that's extra special, or a Sunday roast in a sleepy country pub,
            we've got the brand and venue to suit every occasion. Take a look
            for yourself!
          </p>

          <div className="flex justify-center flex-wrap bg-white pt-6">
            {brands.map((brand, index) => {
              return (
                <div
                  key={index}
                  className="p-4 basis-1/2 md:basis-1/3 lg:basis-[26.666%] text-center flex items-center justify-center"
                >
                  <a href={brand.link} target={brand.target} rel="noreferrer">
                    <img
                      src={brand.logo}
                      alt={brand.name}
                      className="max-w-[200px] max-h-[160px]"
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <PubSearch />
    </Layout>
  )
}

export default OurVenuesPage
