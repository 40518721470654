import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { brandOptions } from "../config"
import Button from "../../reusable/Button"
import { SelectField } from "../../reusable/Form/Fields"
import Autocomplete from "../Autocomplete"
import { useEffect } from "react"

const PubSearch = ({ brand, heading }) => {
  const [location, setLocation] = useState(null)
  const [locationLabel, setLocationLabel] = useState(null)
  const [filters, setFilters] = useState(null)
  const { register, handleSubmit } = useForm()

  const onSubmit = data => {
    setFilters({
      brand: data.brand,
      location: locationLabel,
      coords: location,
    })
  }

  useEffect(() => {
    const path = "/national-search/#mapWrapper"
    if (filters) {
      navigate(path, {
        state: filters,
      })
    }
  }, [filters])

  let defaultBrand = "default"
  switch (brand) {
    case "high st":
      defaultBrand = "High Street"
      break
    case "miller carter":
      defaultBrand = "Miller & Carter"
      break
    case "oneills":
      defaultBrand = "O'Neill's"
      break
    case "stonehouse pizza carvery":
      defaultBrand = "Stonehouse"
      break
    default:
      for (let i = 0; i < brandOptions.length; i++) {
        if (brandOptions[i].value.toLowerCase() === brand) {
          defaultBrand = brandOptions[i].value
          break
        }
      }
      break
  }

  // const title = heading ? heading : "Find a restaurant or pub"

  return (
    <div className={`bg-white relative md:p-4`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`max-w-[1110px] mx-auto flex items-center justify-between gap-4 p-4 z-10 md:gap-6 flex-row flex-wrap`}
      >
        {/* <h5>{title}</h5> */}
        <Autocomplete
          register={register}
          setLocationLabel={setLocationLabel}
          setLocationHooks={setLocation}
        />

        <SelectField
          id="brand"
          label="All Brands"
          disablePlaceholder={false}
          options={brandOptions}
          register={register}
          defaultValue={defaultBrand}
        />

        <div className="">
          <Button
            text="Search"
            buttonStyle="primary"
            colour="orange"
            button={true}
            type="submit"
            disabled={!location}
          />
        </div>
      </form>
    </div>
  )
}

export default PubSearch
